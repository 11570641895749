.sticky-menu ul {
  list-style: none;
  /* Remove the dots */
}

.sticky-menu ul li {
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 3px;
  
}

.sticky-menu li a.active {
  color: blue !important;
  /* Color the text blue for the active/selected element */
}