.styles-module_whatsappButton__IWx9V:after {
  /*This code is for cancelling the floating light*/
  -webkit-animation: none !important;
          animation: none !important;
}

/*style the whatsapp button*/
.styles-module_whatsappButton__IWx9V {
  background-color: #1c55e5 !important;
  position: fixed;
  z-index: 100;
}

/* style the chat box*/
.styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE {
  z-index: 100;
  position: fixed;
  right: 4rem;
  bottom: 6rem;
  height: 380px !important;
}

.closeWhatsappButton {
  background-color: #396ae6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  border-radius: 50%;
  font-weight: 900;
  color: white;
  z-index: 900;
  cursor: pointer;
  -webkit-box-shadow: 0 0 0 0 #25d36500;
          box-shadow: 0 0 0 0 #25d36500;
}

.hideButtons {
  display: none !important;
}

/*change the privacy choices to the bottom left*/
a.iubenda-tp-btn.iubenda-cs-preferences-link {
  position: fixed;
  left: 0.5rem !important;
  bottom: 2rem !important;
  width: 30px !important;
}

/* Extra Small Devices (phones, less than 576px) and Small Devices (tablets, 576px and up) */
@media (max-width: 575.98px), (min-width: 576px) {
  /* Add styles for extra small and small devices here */
  .styles-module_whatsappButton__IWx9V {
    width: 45px !important;
    height: 45px !important;
    bottom: 3.8rem !important;
    right: 1.5rem !important;
  }
  .closeWhatsappButton {
    width: 35px;
    height: 35px;
    font-size: 1rem;
    right: 1.8rem;
    bottom: 1rem;
  }
}

/* Extra Small Devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /*styling the chatbox container*/
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE {
    width: 45% !important;
    /*height: 40% !important;*/
    height: 380px !important;
    position: fixed !important;
    right: 3.5rem !important;
    left: initial !important;
    margin: 0 !important;
    min-width: 260px;
    /*footer*/
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE header {
    /*Avatar section*/
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE header .styles-module_avatar__17BL2 {
    /*the online greent circle*/
    /*Logo*/
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE header .styles-module_avatar__17BL2::after {
    height: 8px;
    width: 7px;
    background-origin: 3px;
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE header .styles-module_avatar__17BL2 img {
    height: 40px;
    width: 40px;
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE header .styles-module_status__3GBr2 {
    /*D4RE Title*/
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE header .styles-module_status__3GBr2 .styles-module_statusTitle__1svrc {
    font-size: 15px;
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE .styles-module_message__2RbY_ {
    max-width: 200px !important;
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE .styles-module_chatFooter__1yhZR {
    /*input*/
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE .styles-module_chatFooter__1yhZR .styles-module_input__2IYfc {
    min-height: 35px !important;
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE .styles-module_chatFooter__1yhZR button svg {
    width: 25px !important;
    height: 25px !important;
  }
}

/* Large Devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Add styles for large devices here */
  .styles-module_whatsappButton__IWx9V {
    width: 50px !important;
    height: 50px !important;
    bottom: 4rem !important;
    right: 2rem !important;
  }
  .closeWhatsappButton {
    bottom: 1rem;
    right: 2.5rem;
    width: 35px;
    height: 35px;
    font-size: 1rem;
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE {
    right: 5rem;
    /* Style the scrollbar */
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE::-webkit-scrollbar {
    width: 1.5px;
    /* Make the scrollbar 1.5px larger */
  }
  .styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Change the color to gray */
  }
}
