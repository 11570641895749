@charset "UTF-8";

@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-Regular.eot");
  src: url("../fonts/Barlow/Barlow-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Barlow/Barlow-Regular.woff") format("woff"),
    url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow/Barlow-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Regular.eot");
  src: url("../fonts/Montserrat/Montserrat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.bold {
  font-weight: bold;
}

.MuiDialogContent-root {
  -webkit-overflow-scrolling: auto;
  -webkit-overflow-scrolling: touch;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

.gm-style-iw-d,
.gm-style-iw,
.gm-style-iw-c {
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}

.gm-style .gm-style-iw-tc {
  display: none;
  visibility: hidden;
}

.gm-style .gm-style-iw-t::after,
.gm-style .gm-style-iw-tc::after {
  background-color: unset !important;
  content: unset !important;
}

.noPadding {
  padding: 0px !important;
  margin-left: 12px !important;
  margin-right: 3px !important;
}

.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 100%;
}

.gm-style-iw-d,
.gm-style-iw,
.gm-style-iw-c {
  max-height: 414px !important;
}