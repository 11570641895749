.requestHomePageCTA {
    margin: 10px 0 11px 7px;
    font-family: Barlow, sans-serif, Verdana;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: -0.22px;
    color: #212121;
}

.sendArequestLinkHomePageCTA {
    margin: 11px 187px 0 7px;
    font-family: Barlow, sans-serif, Verdana;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: -0.22px;
    color: #1c55e5;
}

.no-matches-found {
    margin: 7px 0 11px 7px;
    font-family: Barlow, sans-serif, Verdana;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: -0.22px;
}

.Line-2 {
    width: 100%;
    height: 1px;
    border: solid 1px #eaeaea;
}

.TableHead {
    font-family: "Barlow", sans-serif, Verdana;
    font-size: 16px;
    font-weight: 500;
    line-height: 2;
    color: #6a6a6a;
    padding: 0.5rem 0;
}

.searchBox {
    max-width: 200px;
    font-size: 16px;
}

.TableRowCell {
    margin: 0 98px 0 0;
    font-family: 'Barlow', sans-serif, Verdana, Arial;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #000;
    padding: 1rem 0;
    background-color: lightblue;
}

.ctaFilterContainer  {
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    padding: 10px 20px;
    text-align: center;
}

.ctaFilterText {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.17px;
    color: #6a6a6a;
    margin: 10px;
}


.ctaResultsContainer  {
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    padding: 0 20px 20px;
    text-align: center;
}


.ctaResultsText {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.17px;
    color: #6a6a6a;
    margin: 10px;
}



.ctaAdvancedFilterText {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.17px;
    color: #6a6a6a;
}

.submitButton {
    color: black;
    font-weight: 600;
    padding: 6px 15px;
    font-family: Montserrat, sans-serif;
    margin-left: 6px;
    font-size: 10px;
}

.mobileText {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Barlow', sans-serif, Verdana, Arial;
    font-stretch: normal;
    font-style: normal;
}


.minHeight100 {
    min-height: 100vh;
    margin-top: 3% ;
}


.mainContent {
    flex-grow: 1;
    max-width: 100%;
    overflow: auto;
    padding-top: 80px;
}

.How-it-works {
    width: 100px;
    height: 17px;
    font-family: 'Barlow', sans-serif, Verdana, Arial;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.2px;
    text-align: center;
    color: #1c55e5;
    cursor: pointer;
    text-decoration: underline;

}


.Not-now-tank-you {
    width: 100px;
    height: 17px;
    font-family: 'Barlow', sans-serif, Verdana, Arial;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.2px;
    text-align: center;
    color: white;
    cursor: pointer;
    text-decoration: underline;
}

.How-it-works-searcher {
    width: 100px;
    font-family: 'Barlow', sans-serif, Verdana, Arial;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.2px;
    text-align: end;
    margin: 0px;
    color: #1c55e5;
}


.How-it-works-title {
    width: 148px;
    height: 26px;
    margin: 0px 0px 9% 0px;
    font-family: Montserrat, 'Barlow', sans-serif, Verdana, Arial;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #391400;
}


.How-it-works-title-mobile {
    width: 148px;
    height: 26px;
    margin: 0px 0px 5% 0px;
    font-family: Montserrat, 'Barlow', sans-serif, Verdana, Arial;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #391400;
}


.points-title{
    font-family: 'Barlow', sans-serif, Verdana, Arial;
    font-weight: 600;
    line-height: 1.5;
    margin: 0px;
}

.points-title-mobile{
    font-family: 'Barlow', sans-serif, Verdana, Arial;
    font-weight: 600;
    line-height: 1.5;
    margin: 0px;
}
.points-paragraph{
    font-family: 'Barlow', sans-serif, Verdana, Arial;
    line-height: 1.5;
    margin: 0px;
}


.points-paragraph-mobile{
    font-family: 'Barlow', sans-serif, Verdana, Arial;
    line-height: 1.5;
    margin: 0px;
}

.Trova-limmobile-che {
    font-family: 'Barlow', sans-serif, Verdana, Arial;
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
.text-style-1 {
    font-size: 21px;
    font-weight: 600;
    margin: 0px 0px 5px;
}


.Trova-limmobile-che-mobile {
    font-family: 'Barlow', sans-serif, Verdana, Arial;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
.text-style-1-mobile{
    font-size: 16px;
    font-weight: 600;
    margin: 0px 0px 5px;
}


.Title-area-how-it-works {
    width: 100%;
    display: flex;
    justify-content: center;
}
.body-How-it-works {
    display: flex;
    flex-direction: row;
    height: 70%;
}

.first-section-How-it-works {
    display: flex;
    flex-direction: row;
    height: 70%;
}

.first-section-How-it-works {
    display: flex;
    flex-direction: row;
    height: 70%;
}

